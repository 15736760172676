const TOKEN_NAME = 'hande-token'
const PERMISSION_CODE = 'PermissionCodeList'

export function setToken (token) {
  localStorage.setItem(TOKEN_NAME, token)
}

export function getToken () {
  return localStorage.getItem(TOKEN_NAME)
}

export function removeToken () {
  localStorage.removeItem(TOKEN_NAME)
}
// 存储路由跳转params数据
export const storageParams = (name, data) => {
  // console.log(JSON.stringify(data))
  localStorage.setItem(name, JSON.stringify(data))
}

export function setPermissionCodeList (data) {
  localStorage.setItem(PERMISSION_CODE, JSON.stringify(data))
}
// PERMISSION_CODE
export const getPermissionCodeList = () => {
  return JSON.parse(localStorage.getItem(PERMISSION_CODE))
}
