import axios from 'axios'
import router from '@/router/index.js'
import { Message } from 'element-ui'
import { getToken } from '@/utils/storage'

const instance = axios.create({
  // baseURL:'http://192.168.1.22:8100', // 如果请求3秒后，后台没给响应，直接报错
  timeout: 60000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use( config => {
  
    const token = getToken()
    if (token) {
      config.headers.Hande = `Bearer ${token}`
    }
  return config
})

instance.interceptors.response.use( response => {
  // 这个地方做下全局得错误处理 比如刚刚 code为9999得时候
  if ( response.data.code === 401 || response.data.message === '身份验证已过期，请重新登录') {
      router.push({ name: "login" });
  }
  if (response.data.code === 999 || response.data.code === 400) {
    Message.error(response.data.message)
  }
  if (response.data.code === 413) {
    Message.error("上传文件失败")
  }
  return response.data
},
function (error) {
if (error?.response?.status === 401 || error?.response?.data?.code === 401) {
  // Message.error('登录已过期,请重新登录')
  router.push({ name: "login" })
  return new Promise(function () {})
}
return Promise.reject(error)
})
// )

export { instance }