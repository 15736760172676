import { instance } from '@/utils/request'

// 登录密码
export function posswordLongin (data) {
  return instance({
    method: 'post',
    url: '/v1/auth/login',
    data,
  })
}

//退出登录
export function loginOut () {
  return instance({
    method: 'post',
    url: '/v1/auth/loginOut',
  })
}
//获取手机号码
export function getToken () {
  return instance({
    method: 'post',
    url: '/v1/auth/getToken',
  })
}

//获取角色权限id
export function getPermissionCode () {
  return instance({
    method: 'post',
    url: '/v1/sysUser/getUserPermission',
  })
}