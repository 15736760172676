import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getToken } from '@/utils/storage.js'

NProgress.configure({ showSpinner: false });

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录',
    }
  },
  {
    path: "/",
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '首页',
    },
    children: [
      // 代发表
      { 
        path: '/', 
        component:  () => import('../views/layout/IssuingModule/SurrogateTable.vue'), 
        name: 'SurrogateTable', 
        meta: { title: '代发表', }
      },
       // 代发表详情
       { 
        path: 'PublishDetails', 
        component:  () => import('../views/layout/IssuingModule/PublishDetails.vue'), 
        name: 'PublishDetails', 
        meta: { title: '代发表详情', }
      },
      // 代发人员
      { 
        path: 'IssuePerson', 
        component:  () => import('../views/layout/IssuingModule/IssuePerson.vue'), 
        name: 'Issueperson', 
        meta: { title: '代发人员', }
      },
      // 账户充值记录
      { 
        path: 'rechargeRecord', 
        component:  () => import('../views/layout/IssuingModule/rechargeRecord.vue'), 
        name: 'rechargeRecord', 
        meta: { title: '账户充值记录', }
      },
       // 发薪主体
       { 
        path: 'paymaster', 
        component:  () => import('../views/layout/paymaster/paymaster.vue'), 
        name: 'paymaster', 
        meta: { title: '发薪主体', }
      },
      // 用工企业
      { 
      path: 'EmploymentEnterprise', 
      component:  () => import('../views/layout/paymaster/EmploymentEnterprise.vue'), 
      name: 'EmploymentEnterprise', 
      meta: { title: '用工企业', }
     },
    // 人员账号
     { 
      path: 'PersonnelAccount', 
      component:  () => import('../views/layout/install/PersonnelAccount.vue'), 
      name: 'PersonnelAccount', 
      meta: { title: '人员账号', }
     },
      // 角色权限
      { 
        path: 'character', 
        component:  () => import('../views/layout/install/character.vue'), 
        name: 'character', 
        meta: { title: '角色权限', }
       },
         // 个人中心
      { 
        path: 'personCenter', 
        component:  () => import('../views/layout/personCenter/personCenter.vue'), 
        name: 'personCenter', 
        meta: { title: '个人中心', }
       },
    ]
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if ( getToken() || to.path === '/login') {
    if (to.path === '/login') {
        localStorage.clear()
    }
    next ()
  } else {
    next ('/login')
  }

})
router.afterEach(() => {
  NProgress.done(); //完成进度条
});

export default router;
