import Vue from 'vue'
import Vuex from 'vuex'
import { getPermissionCodeList, setPermissionCodeList } from '@/utils/storage.js'
import { getPermissionCode } from '@/api/login'
Vue.use(Vuex)

const state = {
  PermissionCodeList: getPermissionCodeList() || []
}
const mutations = {
  user_getPermissionCode (state, data) {
   
    state.PermissionCodeList = data || []
  }
}

const actions = {
  async user_getPermissionCode ({ commit }, ) {
    const res = await getPermissionCode()
    const permissionList = res.data || []
    setPermissionCodeList(permissionList)
    commit('user_getPermissionCode', permissionList)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}